import {isMobile} from './global';

class NAV {
  constructor(navItems) {
    this.navObj = navItems.items;
    this.navHolder = document.getElementById('main-nav');
    this.fill = document.getElementById('fill');
    this.mobileNav = document.getElementById('mobile-nav');
    this.mbileNavHolder = document.querySelector('.nav-scroll');
    this.mobileBtn = document.getElementById('mobile-toggle');
    this.mainContent = document.getElementById('main-content-holder');

    this.setNav();
    this.events();
  }
  currentBp = isMobile ? 'phone' : 'desktop'
  setNav(){
    let nav = document.createElement('ul');
    let li = '';
    this.navObj.forEach((item) => {
      if(item.items.length > 0){
        let subNav = '<ul class="sub-nav">';
        let subNavList = '';
        item.items.forEach((subNav) => {
          subNavList += `<li><a href="${subNav.url}">${subNav.label}</a></li>`
        })
        subNav += `${subNavList}</ul>`
        li += `<li class="main-nav__has-drop"><a href="${item.url}">${item.label}</a>${subNav}</li>`
      }else{
        li += `<li><a href="${item.url}">${item.label}</a></li>`
      }
    });
    nav.innerHTML = li;
    nav.className = 'main-nav__primary';

    if(this.currentBp === 'phone'){
      this.mbileNavHolder.appendChild(nav);
    }else{
      this.navHolder.appendChild(nav);
    }
    this.navEvt();
  }
  events(){
    window.addEventListener('resize', this.resizeEvt, false);
    this.fill.addEventListener('click', this.closeNav, false);
    this.mobileBtn.addEventListener('click', this.toggleMobileNav, false);
  }
  navEvt(){
    document.querySelector('.main-nav__primary')
      .addEventListener('click', this.toggleSubNav, false);
  }
  killNavEvt(){
    document.querySelector('.main-nav__primary')
      .removeEventListener('click', this.toggleSubNav, false);
  }
  toggleSubNav = (e) => {
    if(e.target.parentElement.classList.contains('main-nav__has-drop')){
      e.preventDefault();
      let clickItem = e.target.parentElement;
      let current = document.querySelector('.main-nav__primary--active');
      let isOpen = clickItem.classList.contains('main-nav__primary--active');

      //close any open nav item
      if(current !== null &&  !isOpen){
        current.classList.remove('main-nav__primary--active');
      }

      clickItem.classList.toggle('main-nav__primary--active');

      //Toggle overlay
      if(this.currentBp !== 'phone'){
        if(!isOpen){
          this.fill.classList.add('fill--active')
        }else{
          this.fill.classList.remove('fill--active')
        }
      }
    }
  }
  closeNav = (e) =>{
    this.fill.classList.remove('fill--active');
    if(this.currentBp !== 'phone'){
      document.querySelector('.main-nav__primary--active')
        .classList.remove('main-nav__primary--active');
    }else{
      this.mobileNav.classList.remove('mobile-nav--active');
      this.mainContent.classList.remove('mobile-nav--active');
      this.fill.classList.remove('fill--active');

      document.body.classList.remove('mobile-nav--open');
      this.mobileBtn.classList.remove('mobile-nav--active');
    }
  }
  toggleMobileNav = (e) => {
    let isActive = this.mobileNav.classList.contains('mobile-nav--active');

    this.mobileNav.classList.toggle('mobile-nav--active');
    this.mainContent.classList.toggle('mobile-nav--active');
    this.fill.classList.toggle('fill--active');

    document.body.classList.toggle('mobile-nav--open');

    e.currentTarget.classList.toggle('mobile-nav--active');
  }
  resizeEvt = (e) => {
    if(window.innerWidth < 768 && this.currentBp !== 'phone'){
      this.currentBp = 'phone'
      this.killNavEvt();
      this.navHolder.removeChild(document.querySelector('.main-nav__primary'));
      this.setNav();
      this.fill.classList.remove('fill--active');
    }else if(window.innerWidth >= 768 && this.currentBp !== 'desktop'){
      this.currentBp = 'desktop';
      this.mainContent.classList.remove('mobile-nav--active');
      this.mobileNav.classList.remove('mobile-nav--active');
      document.body.classList.remove('mobile-nav--open');
      this.mobileBtn.classList.remove('mobile-nav--active');
      this.killNavEvt();
      this.mbileNavHolder.removeChild(document.querySelector('.main-nav__primary'));
      this.setNav();
      this.fill.classList.remove('fill--active');
    }
  }
}

export default NAV;
